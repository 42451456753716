import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
    const page = window.location.pathname;
    if(!page.endsWith('task_management2/projects')) {
        return;
    }
    // Declare expandedItems at the top scope
    let expandedItems = [];

    const projectsChannel = consumer.subscriptions.create("ProjectsChannel", {
        connected() {
            console.log("Connected to ProjectsChannel");
        },
        disconnected() {
            console.log("Disconnected from ProjectsChannel");
        },
        received(data) {
            // console.log("Received data from ProjectsChannel");
            // console.log("data: ", data);
            // Handle task updates
            if (data.refresh) {
                updateProjectsList(true);  // Ensure triggerSort is true after a broadcast update
                const params = new URLSearchParams(window.location.search);
                if (params.get('taskId') && params.get('taskId') == data.taskId) {
                    const taskId = params.get('taskId');
                    const data = {};
                    params.forEach(function (value, key) {
                        data[key] = value;
                    });
                    runAndReloadContentDynamically('taskContainer', "/admin/tasks/" + taskId + "/reload_modal", 'PATCH', data, refreshModal)
                }
            }
        },
        speak(message) {
            this.perform('speak', { message: message });
        }
    });

    window.projectsChannel = projectsChannel;

    function updateProjectsList(triggerSort = true) {
        const urlParams = new URLSearchParams(window.location.search);
        // console.log("Updating projects list...");

        loadContentDynamically('project_view', '/admin/task_management2/get_projects_view?' + urlParams, function() {
            // console.log("Updated projects list -- SUCCESS");
            setSortableValues(triggerSort);  // Set sortable with the correct triggerSort flag

            // Get expanded items from the URL and store in the expandedItems array
            expandedItems = urlParams.get('expanded') ? urlParams.get('expanded').split(',') : [];
            expandedItems.forEach(itemId => {
                $(`.trigger[data-id="${itemId}"]`).attr("data-expanded", "true");
                $(`.trigger[data-id="${itemId}"]`).closest('li').find('> ul').show(); // Show child items
                $(`.trigger[data-id="${itemId}"]`).css("transform", "rotate(90deg)"); // Rotate icon for expanded state
            });

            $('[data-toggle="popover"]').popover({
                html: true,
                content: function () {
                    const target = $(this).next('.popover');
                    return $(target).first().children().clone();
                }
            });
            $('[data-popover-type="chat"]').on('shown.bs.popover', function () {
                showMessages(this);
            });
        });
    }

    function loadContentDynamically(selector, url, callback = null) {
        let parent = document.getElementById(selector);
        if (parent) {
            fetch(url)
                .then(response => response.text())
                .then(html => {
                    parent.innerHTML = html;
                    if (callback) { callback(); }
                })
                .catch(error => {
                    console.error('Error loading the dynamic content:', error);
                    parent.innerHTML = '<li><span>Error loading content</span></li>';
                });
        }
    }

    function runAndReloadContentDynamically(selector, url, method, data = {}, callback = null) {
        let parent = document.getElementById(selector);
        if (parent) {
            let options = {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content // CSRF token for Rails
                }
            };

            if (method !== 'GET' && method !== 'HEAD') options.body = JSON.stringify(data);

            fetch(url, options)
                .then(response => response.json())
                .then(json => {
                    if (json.success) {
                        parent.innerHTML = json.html;
                        if (callback) callback();
                    } else {
                        // console.error('Error:', json.notice);
                        parent.innerHTML = '<span>Error loading content</span>';
                    }
                })
                .catch(error => {
                    // console.error('Error loading the dynamic content:', error);
                    parent.innerHTML = '<span>Error loading content</span>';
                });
        } else {
            // console.log('Parent element not found:', selector);
        }
    }

    function refreshModal() {
        document.dispatchEvent(new CustomEvent('taskFormLoaded'));
    }

    function collectHierarchy() {
        let hierarchy = [];

        $(".sortable.projects > .sortable-item").each(function() {
            let projectId = $(this).data("id");
            let project = {
                id: projectId,
                stages: []
            };

            $(this).find("> .sortable.stages > .sortable-item").each(function() {
                let stageId = $(this).data("id");
                let stage = {
                    id: stageId,
                    tasks: []
                };

                $(this).find("> .sortable.tasks > .sortable-item").each(function() {
                    let taskId = $(this).data("id");
                    stage.tasks.push({ id: taskId });
                });

                project.stages.push(stage);
            });

            hierarchy.push(project);
        });

        return hierarchy;
    }

    function setSortableValues(triggerSort = true) {
        $(".sortable").each(function() {
            let $sortable = $(this);

            function updatePlaceholder() {
                if ($sortable.children('.sortable-item').length > 0) {
                    $sortable.children('.sortable-placeholder').hide();
                } else {
                    $sortable.children('.sortable-placeholder').show();
                }
            }

            updatePlaceholder();

            $sortable.sortable({
                connectWith: ".sortable",
                items: "> .sortable-item",
                handle: ".sortable-handle",
                placeholder: "sortable-placeholder",
                dropOnEmpty: true,
                update: function(event, ui) {
                    const item = ui.item;
                    const parent = item.parent();
                    const classes = parent.attr('class').split(' ').filter(function(cls) {
                        return cls.indexOf('-item') === -1 && cls !== 'sortable';
                    });
                    const parentClass = classes.filter(function(cls) {
                        return cls !== 'ui-sortable' && cls !== 'task';
                    })[0];

                    let shouldUpdate = true;

                    const hasChildren = item.find('.sortable-item').length > 0;

                    if (item.hasClass('task-item') && parentClass === 'projects') {
                        item.addClass('project-item').removeClass('task-item');
                        item.find(".sortable").addClass('stages').removeClass('tasks');
                        item.find(".sortable").sortable('enable');
                    }

                    if (item.hasClass('stage-item')) {
                        if (parentClass === 'projects') {
                            if (hasChildren) {
                                shouldUpdate = false;
                                $(this).sortable('cancel');
                            } else {
                                item.addClass('project-item').removeClass('stage-item');
                                item.find(".sortable").sortable('enable');
                            }
                        } else if (parentClass === 'tasks') {
                            if (hasChildren) {
                                shouldUpdate = false;
                                $(this).sortable('cancel');
                            } else {
                                item.addClass('task-item').removeClass('stage-item');
                                item.find(".sortable").removeClass('tasks');
                                item.find(".sortable").sortable('disable');
                            }
                        }
                    }

                    if (item.hasClass('project-item')) {
                        if (parentClass === 'stages') {
                            if (hasChildren) {
                                shouldUpdate = false;
                                $(this).sortable('cancel');
                            } else {
                                item.addClass('stage-item').removeClass('project-item');
                                item.find(".sortable").sortable('enable');
                            }
                        } else if (parentClass === 'tasks') {
                            if (hasChildren) {
                                shouldUpdate = false;
                                $(this).sortable('cancel');
                            } else {
                                item.addClass('task-item').removeClass('project-item');
                                item.find(".sortable").removeClass('stages');
                                item.find(".sortable").sortable('disable');
                            }
                        }
                    }

                    if (item.hasClass('task-item') && parentClass === 'stages') {
                        item.addClass('stage-item').removeClass('task-item');
                        item.find(".sortable").addClass('tasks').removeClass('stages');
                        item.find(".sortable").sortable('enable');
                    }

                    if (!shouldUpdate) {
                        return;
                    }
                    // Determine if the current view is a projects view (hierarchical) or todos view (flat)
                    if ($(".project-view").length === 0) {  // If there's no project-view class, it's a todos view
                        const taskIds = [];
                        $(".sortable > .sortable-item").each(function() {
                            const fullId = $(this).attr("id");  // Get the full id attribute (e.g., "task_81")
                            // console.log("Full ID:", fullId);
                            const taskId = fullId.split("_")[1];  // Split by "_" and get the second part (the number)
                            taskIds.push(taskId);  // Push the extracted task ID to the array
                        });

                        // Send the simple sort for todos
                        $.ajax({
                            url: '/admin/tasks/sort', // Simple sort route
                            method: 'POST',
                            data: {
                                task: taskIds // Send the flat list of task IDs
                            },
                            success: function(response) {
                                // console.log("Todos order updated successfully.");
                            },
                            error: function(error) {
                                // console.error("Failed to update todos order:", error);
                            }
                        });
                    } else {
                        // Hierarchical view: collect the hierarchy of projects, stages, and tasks
                        const hierarchy = collectHierarchy();
                        // console.log("Hierarchy:", hierarchy);

                        // Perform AJAX request to send hierarchy to the backend
                        $.ajax({
                            url: '/admin/tasks/sort_inside_parent', // Hierarchical sort route
                            method: 'POST',
                            data: {
                                hierarchy: hierarchy // Send the nested structure
                            },
                            success: function(response) {
                                // console.log("Projects order updated successfully.");
                            },
                            error: function(error) {
                                // console.error("Failed to update projects order:", error);
                            }
                        });
                    }
                }
            }).disableSelection();

            if ($sortable.hasClass('task')) {
                $sortable.sortable('disable');
                $sortable.removeClass('task');
            }
        });

        // Restore expanded state from the URL
        const urlParams = new URLSearchParams(window.location.search);
        let expandedItems = urlParams.get('expanded') ? urlParams.get('expanded').split(',') : [];

        expandedItems.forEach(itemId => {
            $(`.trigger[data-id="${itemId}"]`).attr("data-expanded", "true");
            $(`.trigger[data-id="${itemId}"]`).closest('li').find('> ul').show(); // Show child items
            $(`.trigger[data-id="${itemId}"]`).css("transform", "rotate(90deg)"); // Rotate icon for expanded state
        });

        // Re-bind the trigger click event to the dynamically loaded elements
        $(".trigger").on("click", function () {
            const expanded = $(this).attr("data-expanded");
            const itemId = $(this).data("id"); // Assuming each trigger has a unique data-id attribute

            if (expanded === "true") {
                $(this).attr("data-expanded", "false");
                $(this).closest('li').find('> ul').hide(); // Collapse child items
                $(this).css("transform", "rotate(0)"); // Rotate icon for expanded state
                expandedItems = expandedItems.filter(id => id !== String(itemId)); // Remove from expanded list
            } else {
                $(this).attr("data-expanded", "true");
                $(this).closest('li').find('> ul').show(); // Expand child items
                $(this).css("transform", "rotate(90deg)"); // Rotate icon for expanded state
                expandedItems.push(String(itemId)); // Add to expanded list
            }

            // Update the URL with the expanded items (without reloading the page)
            urlParams.set('expanded', expandedItems.join(','));
            const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
            ReplaceURL(expandedItems);
            window.history.replaceState({}, '', newUrl); // Update the URL without reloading the page
        });

    }

    function ReplaceURL(expandedItems){
        const links = document.getElementsByClassName('project-action-link')
        for (const idx in links){
            const link = links[idx]
            if (!link.getAttribute){
                continue;
            }

            const href = link.getAttribute('href');

            const [baseUrl, queryString] = href.split('?');

            if (queryString) {
                const params = new URLSearchParams(queryString);
                params.set('expanded', expandedItems.join(','));

                const newUrl = `${baseUrl}?${params.toString()}`;
                link.setAttribute('href', newUrl);
            }
        }
    }

});
