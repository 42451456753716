import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
    const page = window.location.pathname;
    if(!page.endsWith('task_management2')) {
        return;
    }

    const chatInput = document.getElementById('chat-input');
    const sendButton = document.getElementById('send-button');

    const tasksChannel = consumer.subscriptions.create("TasksChannel", {
        connected() {
            console.log("Connected to TasksChannel");
        },
        disconnected() {
            console.log("Disconnected from TasksChannel");
        },
        received(data) {
            // Handle task updates

            if (data.refresh) {
                updateTasksList();
                updateCompletedTaskList();
                const params = new URLSearchParams(window.location.search);
                if (params.get('taskId') && params.get('taskId') == data.taskId) {
                    const taskId = params.get('taskId');
                    const data = {};
                    params.forEach(function (value, key) {
                        data[key] = value;
                    });
                    runAndReloadContentDynamically('taskContainer', "/admin/tasks/" + taskId + "/reload_modal", 'PATCH', data, refreshModal)
                }
            }
        },
        speak(message) {
            this.perform('speak', { message: message });
        }
    });

    if (sendButton){
        sendButton.addEventListener('click', () => {
            tasksChannel.speak(chatInput.value);
            chatInput.value = '';
        });
    }

    window.tasksChannel = tasksChannel; // Make the tasksChannel available globally for testing

    function updateTasksList() {
        // Pass the current URL params to get_task_list
        let currentParams = new URLSearchParams(window.location.search);
        // assure it does not have the completed_refresh param
        currentParams.delete('completed_refresh');
        let url = `/admin/task_management2/get_task_list?${currentParams.toString()}`;
        let archived = window.location.pathname.split('/').pop();
        if (archived === 'archived') {
            loadContentDynamically('tasks-table-archived', url);
        } else{
            loadContentDynamically('tasks-table', url);
        }
    }

    function updateCompletedTaskList() {
        // Pass the current URL params and add the completed_refresh=true param
        let currentParams = new URLSearchParams(window.location.search);
        currentParams.set('completed_refresh', 'true');
        let url = `/admin/task_management2/get_task_list?${currentParams.toString()}`;
        loadContentDynamically('completed-tasks', url, () => {
            $('[data-toggle="popover"]').popover({
                html: true,
                content: function () {
                    const target = $(this).next('.popover');
                    return $(target).first().children().clone();
                }
            });
        });
        $('[data-popover-type="chat"]').on('shown.bs.popover', function () {
            showMessages(this);
        });
    }
    function runAndReloadContentDynamically(selector, url, method, data = {}, callback = null) {
        let parent = document.getElementById(selector);
        if (parent) {
            let options = {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content // CSRF token for Rails
                }
            };

            if (method !== 'GET' && method !== 'HEAD') options.body = JSON.stringify(data);

            fetch(url, options)
                .then(response => response.json())
                .then(json => {
                    if (json.success) {
                        parent.innerHTML = json.html;
                        if (callback) callback();
                    } else {
                        // console.error('Error:', json.notice);
                        parent.innerHTML = '<span>Error loading content</span>';
                    }
                })
                .catch(error => {
                    // console.error('Error loading the dynamic content:', error);
                    parent.innerHTML = '<span>Error loading content</span>';
                });
        } else {
            // console.log('Parent element not found:', selector);
        }
    }
    function refreshModal() {
        document.dispatchEvent(new CustomEvent('taskFormLoaded'));
    }


    function loadContentDynamically(selector, url, callback = null){
        let parent = document.getElementById(selector);
        if (parent) {
            fetch(url)
                .then(response => response.text())
                .then(html => {
                    parent.innerHTML = html;
                    if (callback) { callback(); }
                })
                .then(() => {
                    setSortableValues();
                })
                .catch(error => {
                    console.error('Error loading the dynamic content:', error);
                    parent.innerHTML = '<li><span>Error loading content</span></li>';
                });
        }
    }

    function setSortableValues() {
        $(".sortable").sortable({
            axis: "y", // Simplified for demonstration; adapt as necessary
            items: ".sortable-item",
            handle: ".sortable-handle",
            update: function(event, ui) {
                // Assuming you have a way to serialize and save the new order
                var sortedData = $(this).sortable('serialize');
                $.post($(this).data("sortable-url"), sortedData);
            }
        }).disableSelection();
    }
});
