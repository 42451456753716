import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "purchaseOrder",
        "shippingMethod",
        "freightCosts",
        "lineItems",
        "lineItem",
        "noProducts",
        "subtotal",
        "salesTax",
        "total",
        "form",
        "ship",
        "willCall"
    ]

    connect() {
        console.log("New Order controller connected")
    }

    selectEmail() {
        this.purchaseOrderTarget.classList.remove("hidden")
    }

    selectPhone() {
        this.purchaseOrderTarget.classList.add("hidden")
    }

    selectAddress(event) {
        let url = $(event.currentTarget).find(":selected").data("shipping-methods-url")

        fetch(url, {
            credentials: "same-origin"
        })
            .then(response => {
                // console.log("Got shipping methods for address", response)
                return response
            })
            .then(response => response.text())
            .then(html => {
                this.shippingMethodTarget.innerHTML = html
                this.fetchFreightCosts()
            })
    }

    fetchFreightCosts() {
        let url = this.data.get('freight-costs-url')

        fetch(url, {
            method: "POST",
            body: $(this.formTarget).serialize(),
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                // console.log("Got freight costs", response)
                return response
            })
            .then(response => response.text())
            .then(html => {
                // console.log("Adding freight costs to DOM", html)
                this.freightCostsTarget.innerHTML = html
            })
    }

    addProduct(event) {
        event.preventDefault()

        // console.log("Adding product")

        this.hideNoProductsMessage()
        this.copyProductElement(event.currentTarget)
        this.clearProductSearch()
        this.clearProductResults()
        this.recalculateTotals()
        this.fetchFreightCosts()
    }

    removeProduct(event) {
        // console.log("Remove product")

        $(event.currentTarget).closest('.line_item').remove()
        this.recalculateTotals()
        this.possiblyShowNoProductsMessage()
        this.fetchFreightCosts()
    }

    hideNoProductsMessage() {
        this.noProductsTarget.classList.add("hidden")
    }

    possiblyShowNoProductsMessage() {
        if (!this.lineItemTargets.length) {
            this.noProductsTarget.classList.remove("hidden")
        }
    }

    copyProductElement(productElement) {
        // console.log("Copying product element")

        $(productElement).closest('.line_item').clone().appendTo(this.lineItemsTarget)
    }

    clearProductSearch() {
        // console.log("Clear product search")

        $("#product-search").val("")
    }

    clearProductResults() {
        // console.log("Clear product results")

        $("#product-search-results").html("")
    }

    recalculateTotals() {
        // console.log("Recalculating order total")

        let subtotal = 0.0
        let salesTax = 0.0

        this.lineItemTargets.forEach((el, i) => {
            let quantity = $(el).find(".quantity-field").val().length ? parseInt($(el).find(".quantity-field").val()) : 0
            let price = $(el).find(".price-field").val().length ? parseFloat($(el).find(".price-field").val()) : 0.0
            subtotal += (quantity * price)
        })

        if (this.data.get("sales-tax") === "true") {
            let sales_tax = this.data.get("sales-tax-value")
            salesTax = subtotal * sales_tax
        }

        this.subtotalTarget.innerHTML = `$${subtotal.toFixed(2)}`
        this.salesTaxTarget.innerHTML = `$${salesTax.toFixed(2)}`
        this.totalTarget.innerHTML = `$${(subtotal + salesTax).toFixed(2)}`
    }

    submit(event) {
        // console.log("Checking if trucking options are necessary")

        event.preventDefault()

        fetch(this.data.get('trucking-options-url'), {
            method: "POST",
            body: $(this.formTarget).serialize(),
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                // console.log("Checked if order needs shipping options:", response)
                return response
            })
            .then(response => {
                // console.log("Response status: ", response.status)

                switch (response.status) {
                    case 200:
                        // console.log("Submitting form")
                        this.formTarget.submit()
                        break
                    case 400:
                        // console.log("Showing form with errors")
                        response.text().then(html => {
                            $(this.formTarget).replaceWith(html)
                        })
                        break
                    case 428:
                        // console.log("Showing shipping options modal")
                        response.text().then(text => {
                            $("#shipping_options_modal").modal()
                        })
                        break
                }
            })
    }

    showShip(event) {
        event.preventDefault()

        // console.log("Showing ship")
        this.shipTarget.style.display = "block"
        this.hideWillCall()
    }

    hideWillCall() {
        // console.log("Hide will call")
        this.willCallTarget.style.display = "none"
    }

    showWillCall(event) {
        event.preventDefault()

        // console.log("Showing will call")
        this.willCallTarget.style.display = "block"
        this.hideShip()
    }

    hideShip() {
        // console.log("Hide ship")
        this.shipTarget.style.display = "none"
    }
}
