// app/javascript/controllers/store/order_controller.js
import {Controller} from "@hotwired/stimulus"

// Connects with data-controller="store--order"
export default class extends Controller {
    static targets = ["form", "submit"]

    connect() {
        console.log("Order controller connected")
    }

    submit(event) {
        console.log("Checking if trucking options are necessary")

        event.preventDefault()

        fetch(this.data.get('preflight-url'), {
            method: "POST",
            body: $(this.formTarget).serialize(),
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                console.log("Checked if order needs shipping options:", response)
                return response
            })
            .then(response => {
                console.log("Response status: ", response.status)

                switch (response.status) {
                    case 200:
                        console.log("Submitting form to preview")
                        $("#new_website_order").submit()
                        break
                    case 400:
                        console.log("Showing form with errors")
                        response.text().then(text => {
                            $("#new_website_order").replaceWith(text)
                        })
                        break
                    case 428:
                        console.log("Showing shipping options modal")
                        response.text().then(text => {
                            $("#shipping_options_modal").modal()
                        })
                        break
                }
            })
    }
}
